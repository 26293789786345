import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import './mentions-legales.scss'

export default function MentionsLegales() {

    return (
        <Layout>
            <div id="mentions_legales">
                <section className="page-title">
                    <StaticImage
                        src="../images/fond-contact.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                    <div className="auto-container relative">
                        <div className="content-box">
                            <div className="content-wrapper">
                                <div className="title">
                                    <h1>Mentions Légales</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay_absolute_6" />
                </section>

                <div className="page_mentions_legales section">
                    <section className="relative bloc_mentions_legales">
                        <div className="text_mentions_legales">
                            <div className="separator_margin">
                                <h3>Responsable de publication</h3>
                                <p><span className="bold">Responsable :</span> Joël AVOCAT</p>
                                <p><span className="bold">Nom commercial :</span> GARAGE AVOCAT</p>
                                <p><span className="bold">Statut juridique :</span> Société à responsabilité limitée</p>
                                <p><span className="bold">Adresse :</span> 40 route de Domelin 73270 Beaufort</p>
                                <p><span className="bold">N° SIRET :</span> 34916435000010</p>
                                <p><span className="bold">Code APE :</span> 4520A Entretien et réparation de véhicules automobiles légers</p>
                            </div>
                            <div className="separator_margin">
                                <h3>Conception et Hébergement</h3>
                                <p><span className="bold">Conception :</span> TA Informatique, siège social au 290 avenue des 16èmes JO d'hiver - 73200 Albertville - <a href="https://www.topastuces.fr">www.topastuces.fr</a></p>
                                <p><span className="bold">Domaine :</span> OVH SAS, siège social au 2 rue Kellermann - 59100 Roubaix - France</p>
                                <p><span className="bold">Hébergement :</span> Netlify, siège social au 2325 3rd Street, Suite 215, San Francisco, California 94107</p>
                            </div>
                            <div className="separator_margin">
                                <h3>Condition Générales d'Utilisation</h3>
                                <p className="bold">Cookies</p>
                                <p>Les informations obtenues par le biais du formulaire de contact, des cookies et de l’outil d’analyse de trafic sont destinées au seul usage de Joël AVOCAT. Ces informations sont réservées à un usage d’analyse et ne pourront être vendues ou mises à la disposition de tierces parties.</p>
                                <p>Les données enregistrés par l'utilisateur lors de l'inscription à la newsletter seront gardées jusqu'à ce que l'utilisateur se désabonne via le lien de désabonnement situé en bas de la newsletter.</p>
                                <p>Joël AVOCAT se réserve le droit de modifier, à tout moment et sans préavis, les présentes CGU afin de les adapter aux évolutions du Site et/ou de son exploitation. Un email sera envoyé pour prévenir les utilisateurs enregistrés des nouvelles CGU.</p>
                                <p className="bold">Déclaration de la CNIL</p>
                                <p>En application de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez des droits d’opposition (art. 26), d’accès (art. 34 à 38) et de rectification (art. 36) des données vous concernant. Pour l’exercer, vous pouvez faire votre demande à Joël AVOCAT, responsable de publication du site www.alpautoecole.fr via le formulaire de contact de ce site.</p>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </Layout>
    )
}